body {
  font-family: Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

.logo-text {
  margin: 0px 0px 0px 20px;
  padding: 0px;
  height: 20px;
}

.App-logo {
  margin: 0px;
  padding: 0px;
  height: 36px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 4s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dark-wrapper {
  background: url(./images/gradient-left-darker.png) repeat-y top left,
    url(./images/gradient-right-darker.png) repeat-y top right, #22021b;
}

.form-button {
  cursor: pointer;
  margin: auto 8px;
  padding: 26px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  min-height: 52px;
  border: none !important;
  border-radius: 4px;
  background: linear-gradient(to top, #420583, #df00fc) !important;
  font-family: Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  opacity: 1;
  transition: all 0.6s ease-out !important;
  z-index: 1;
}

.form-button-text {
  position: relative;
  display: block;
  z-index: 3;
  opacity: 1 !important;
  color: #fff;
  text-decoration: none;
  font-family: Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

.menu-button {
  cursor: pointer;
  margin: auto 8px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  height: 40px;
  border-radius: 4px;
  border: none !important;
  background: linear-gradient(to top, #420583, #df00fc) !important;
  transition: all 0.6s ease-out !important;
  transform: skewX(-18deg) !important;
  font-family: Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

.menu-button-text {
  color: #fff;
  text-decoration: none;
  line-height: 1.5;
  font-size: 0.75rem;
  transform: skewX(18deg);
  font-family: Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

.footer-brand-name {
  margin: 0px;
  padding: 0px;
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  padding: 5px 0;
  line-height: 1.5;
  font-size: 1.4rem;
  letter-spacing: 3px;
  text-align: center;
  text-decoration: none !important;
  font-family: Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

.footer-brand-subtitle {
  margin: 0px;
  padding: 0px;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.65rem;
  color: #df00fc;
  line-height: 1.5;
  text-align: center;
  text-decoration: none !important;
  font-family: Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

.footer-link {
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8rem;
  color: #fff;
  padding: 10px 0;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  font-family: Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

.footer-link:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform-origin: right center;
  height: 2px;
  background: #059330;
  background: linear-gradient(to right, #059330, #3399ff) !important;
  transform: scale(0, 1);
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.footer-link:hover:before {
  transform-origin: left center;
  transform: scale(1, 1);
}

.footer-copy-right {
  margin: 0px;
  padding: 0px;
  width: 100%;
  text-align: center;
  position: relative;
  color: #df00fc;
  font-size: 0.85rem;
  z-index: 2;
  font-family: Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

.dark-adopt-button-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: #c5bdbd !important;
}

.mui-phone-number button {
  width: 30px;
  height: 30px;
}

.card-wrapper {
  overflow: hidden;
}

.card-wrapper .hover-zoom:hover {
  transform: scale3d(1.04, 1.04, 1);
  box-shadow: 3;
}

.card-wrapper:hover .hover-bg-color {
  background-color: #eeedee;
}
